export default function Organization() {
  return (
    <svg
      width="29"
      height="29"
      version="1.1"
      viewBox="0 0 13.229 13.494"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g transform="matrix(.37522 0 0 .37522 -40.605 -61.229)">
        <circle
          cx="125.8"
          cy="165.03"
          r="1.515"
        />
        <path d="m113.8 171.24c3.7977-1.0482 7.4525-2.2684 10.366-4.9033 1.0896 0.97003 2.1792 0.9154 3.2689-0.086 2.8479 2.4559 6.1346 4.2107 10.624 4.9463 0.53788 0.0881 0.81592 0.6405 0.30108 1.1183-1.0959 1.017-2.2115 2.2796-3.699 2.2796h-16.731c-1.7774 0-3.2012-1.1884-4.443-2.2796-0.48987-0.43051-0.18779-0.93685 0.31386-1.0753z" />
        <path d="m110.83 179.67c2.2358-0.50581 4.4345-0.86166 6.4087-2.0215v-2.1506h1.4624v2.1506h14.022v-2.1506h1.4624v2.1936c1.9585 1.0149 4.0808 1.634 6.4087 1.9785 0.63576 0.0941 0.87533 0.51681 0.43011 1.1613-0.90662 1.3124-2.463 2.3656-4.1291 2.3656h-21.205c-2.0096 0-3.7834-0.68165-5.1184-2.1506-0.67089-0.73824-0.58465-1.1857 0.25807-1.3764z" />
        <path d="m108.81 189.3c2.3128-0.6197 4.315-1.9908 6.3227-3.1799v-2.1536h1.4624v2.1536h18.323v-2.1966h1.4194v2.1966c2.1473 1.3405 4.2952 2.5006 6.5808 3.1799 0.74423 0.22118 0.65428 0.72643 0.21506 1.2473-0.895 1.0615-2.0772 1.8065-3.3979 1.8065h-26.839c-1.7873 0-3.2963-0.61623-4.3872-1.8065 0 0-0.83317-0.94342 0.30108-1.2473z" />
        <path d="m112.98 193.05v4.9465c0 0.57543 0.25127 0.81701 0.68781 0.81701h24.086c0.55867 0 0.77463-0.31151 0.77463-0.77412v-4.9894zm8.5592 1.5053h8.5163v2.8386h-8.5163z" />
      </g>
    </svg>
  );
}
