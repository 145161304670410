import "./LearningPage.css";
import Flag from "../User/Flag";
import React from "react";
import Card from "@kamae-apps/shared/Component/Card/Card";
import PrimaryButton from "@kamae-apps/shared/Component/Button/PrimaryButton";
import {useNavigate, useSearchParams} from "react-router-dom";
import {useTranslation} from "react-i18next";
import useIntercom from "../../hooks/useIntercom";

export default function LearningPage() {
  const navigate = useNavigate();
  const { t } = useTranslation("learningPage");
  const [searchParams] = useSearchParams();
  const phishingId = searchParams.get("Id")
  const redirect = (phishingId === null)? "/phishing" : `/phishing/${phishingId}`
  useIntercom().shutdownIntercom()
  return (
    <div className={"trapped-gradiant w-screen h-screen"}>
      <div className={"w-full h-20 flex row justify-between items-center"}>
        <img
          src={"/logo_plateforme.png"}
          alt={t("logo.alt")}
          className={"mx-8 mb-3 w-32"}
        />
        <Flag allLangs={true}/>
      </div>
      <section>
        <Card className={"w-11/12 lg:w-1/3 sm:w-3/5 p-5 flex flex-col gap-10 m-auto"}>
          <img
            src={"/trappedIllustration.png"}
            alt={t("content.image.alt")}
          />
          <p className={"text-4xl"}>
            {t("content.title")}
          </p>
          <div className={"flex flex-col gap-5"}>
            <p className={"text-xl font-gilroy-medium"}>
              {t("content.description.first")}
            </p>
            <p className={"text-xl font-gilroy-medium"}>
              {t("content.description.second")}
            </p>
          </div>
          <PrimaryButton className={"self-center !bg-primary !text-white hover:!bg-white hover:!text-primary"}  onClick={() => {
            location.replace(redirect)
          }}>
            {t("content.button")}
          </PrimaryButton>
          <p className={"font-gilroy-medium text-gray-400"}>
            {t("content.disclaimer")}
          </p>
        </Card>
      </section>
    </div>
  )
}
