import { BeltColor } from "@kamae-apps/shared/Types/Belt/BeltColor";
import { TFunction } from "i18next";

export function getNameFromBelt(belt: BeltColor, t: TFunction) {
  switch (belt) {
    case BeltColor.white:
      return t("app:newBeltModal.belts.white");
    case BeltColor.yellow:
      return t("app:newBeltModal.belts.yellow");
    case BeltColor.orange:
      return t("app:newBeltModal.belts.orange");
    case BeltColor.green:
      return t("app:newBeltModal.belts.green");
    case BeltColor.blue:
      return t("app:newBeltModal.belts.blue");
    case BeltColor.purple:
      return t("app:newBeltModal.belts.purple");
    case BeltColor.brown:
      return t("app:newBeltModal.belts.brown");
    case BeltColor.black:
      return t("app:newBeltModal.belts.black");
    case BeltColor.red:
      return t("app:newBeltModal.belts.red");
    case BeltColor.bronze:
      return t("app:newBeltModal.belts.bronze");
    case BeltColor.silver:
      return t("app:newBeltModal.belts.silver");
    case BeltColor.gold:
      return t("app:newBeltModal.belts.gold");
    case BeltColor.god:
      return t("app:newBeltModal.belts.god");
  }
}
