import "./BotPage.css";
import { useLandbot } from "./useLandbot";
import MaintenancePage from "../../Error/MaintenancePage/MaintenancePage";

export default function BotPage() {
  const { loadingError } = useLandbot();

  if (loadingError) {
    return <MaintenancePage />;
  }

  return <></>;
}
