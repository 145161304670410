import { ReactNode } from "react";
import clsx from "clsx";

import "../../Style/Loadable.css";

interface LoadableProps {
  loaded?: boolean;
  children?: ReactNode;
  minWidth?: string;
  maxWidth?: string;
  minHeight?: string;
  maxHeight?: string;
  className?: string;
}

export default function Loadable(props: LoadableProps) {
  const css = clsx(
    props.minWidth,
    props.maxWidth,
    props.minHeight || "min-h-4",
    props.maxHeight,
    "rounded",
    props.className,
    props.loaded || "loadable bg-gray-300"
  );
  return (
    <div className={css}>{props.loaded === true ? props.children : ""}</div>
  );
}
