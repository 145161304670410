import { ChevronDown } from "react-feather";
import React, { useEffect, useRef, useState } from "react";
import clsx from "clsx";

// Use it to separate element in dropdown
export function DropDownSeparator(props: { className?: string }) {
  const css = clsx("mx-2 border-b-2 border-gray-400", props.className);
  return <div className={css} />;
}

interface DropDownProps {
  title: string | JSX.Element;
  children?: string | JSX.Element | (JSX.Element | string)[];
  className?: string;
}

export function DropDown(props: DropDownProps) {
  const container = useRef<HTMLDivElement>(null);
  const [display, setDisplay] = useState(false);
  const displayRef = useRef<boolean>();
  const handleIn = () => {
    setDisplay(!display);
  };
  displayRef.current = display;
  const handleOut = (e: MouseEvent) => {
    if (
      container.current &&
      !container.current.contains(e.target as Node) &&
      displayRef.current
    ) {
      setDisplay(false);
    }
  };
  useEffect(() => {
    document.addEventListener("mousedown", handleOut);
    return () => {
      document.removeEventListener("mousedown", handleOut);
    };
  }, []);
  const bodyCss = clsx(
    display || "hidden",
    "border-1 absolute right-2 top-full z-10 max-w-screen-xl whitespace-nowrap rounded-lg border-gray-400 bg-white p-2 shadow hover:drop-shadow"
  );
  const cssArrow = clsx(
    display && "rotate-180",
    "no-fill transition-transform"
  );
  return (
    <div
      ref={container}
      className={"relative flex"}
    >
      <button
        className={
          "flex items-center rounded-lg px-4 py-2.5 text-sm font-medium"
        }
        onClick={handleIn}
      >
        {props.title} <ChevronDown className={cssArrow} />
      </button>
      <div
        className={bodyCss}
        onClick={() => setDisplay(false)}
      >
        {props.children}
      </div>
    </div>
  );
}
