import TUser from "@kamae-apps/shared/Types/TUser";
import { TCompany } from "@kamae-apps/shared/Types/Company/TCompany";
import { CompanyStatus } from "@kamae-apps/shared/Types/Company/CompanyStatus";
import { createContext, ReactNode, useContext, useState } from "react";
import { DefaultLanguage } from "@kamae-apps/shared/Types/Company/DefaultLanguage";

const initCompany: TCompany = {
  name: "Kamaé",
  status: CompanyStatus.Customer,
  level: 0,
  creation: new Date(),
  global_score: 0,
  logo: "default.png",
  progress: 0,
  id: 0,
  total_user: 0,
  automated_phishing_activated: false,
  phishing_enabled: false,
  sso_enabled: false,
  lms_enabled: false,
  defaultLanguageId: DefaultLanguage.FR,
  dir_sync_enabled: false,
};

const initContext: TUser = {
  avatar: "",
  first_name: "",
  last_name: "",
  level: 0,
  status: 1,
  team: {
    name: "",
    global_score: 0,
    level: 0,
    company: initCompany,
    id: 0,
    progress: 0,
    user_count: 0,
  },
  languageId: DefaultLanguage.FR,
  company: initCompany,
  id: 0,
  intercom_hash: "",
  global_score: 0,
  email: "kamae@kamae.io",
  job: "",
  progress: 0,
  is_lms_user: false,
};

const useUserState = () => useState(initContext);

const UserContext = createContext<ReturnType<typeof useUserState> | null>(null);

export function UserStateProvider(props: { children: ReactNode }) {
  const [user, setUser] = useUserState();
  return (
    <UserContext.Provider value={[user, setUser]}>
      {props.children}
    </UserContext.Provider>
  );
}

export const useUserSharedState = () => {
  const value = useContext(UserContext);
  if (value === null) {
    throw new Error("No provider for user state");
  }
  return value;
};
