import { useSidebarSharedState } from "./Context";
import { useCallback, useMemo } from "react";

export default function useSidebar() {
  const [sidebar, setSidebar] = useSidebarSharedState();
  return {
    setAvatar: useCallback(
      (value: string) => {
        setSidebar((prev) => {
          return { active: prev.active, avatar: value };
        });
      },
      [setSidebar]
    ),
    resetAvatar: useCallback(() => {
      setSidebar((prev) => {
        return { active: prev.active, avatar: null };
      });
    }, [setSidebar]),
    setActive: useCallback(
      (value: ((prevState: boolean) => boolean) | boolean) => {
        if (typeof value === "boolean") {
          setSidebar((prev) => {
            return { active: value, avatar: prev.avatar };
          });
        } else if (typeof value === "function") {
          setSidebar((prev) => {
            return { active: value(prev.active), avatar: prev.avatar };
          });
        }
      },
      [setSidebar]
    ),
    avatar: useMemo(() => sidebar.avatar, [sidebar.avatar]),
    active: useMemo(() => sidebar.active, [sidebar.active]),
  };
}
