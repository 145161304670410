export default function Document(props: { size?: number }) {
  return (
    <svg
      width={props.size ?? "29"}
      height={props.size ?? "29"}
      version="1.1"
      viewBox="0 0 13.229 13.229"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g transform="matrix(.097613 0 0 .097613 9.9829 -5.8796)">
        <path d="m-102.27 89.577h36.194v-9.3765c0-1.4243-0.94296-1.9599-2.135-1.9599h-32.032c-1.1631 0-2.0272 0.79466-2.0272 1.9599z" />
        <path d="m-102.27 94.073v74.744h36.194v-74.628zm9.0806 6.8404h18.1c1.1849 0 2.1136 0.86205 2.1136 1.8831v18.715c0 0.8199-0.6223 1.9213-1.5756 1.9213h-18.638c-1.3777 0-2.3053-0.94393-2.3053-1.9213v-18.715c0-1.2345 1.0832-1.8831 2.3053-1.8831z" />
        <path d="m-102.27 173.1h36.14v4.609h-36.14z" />
        <path d="m-102.27 182.21h36.194v11.207c0 0.9689-0.6507 2.2825-1.8478 2.2825h-32.227c-1.1813 0-2.1189-1.0128-2.1195-1.9565z" />
        <path d="m-61.562 69.133v-6.7634c0-1.0243 0.4879-2.0751 1.883-2.0751h25.401c0.94983 0 1.883 0.55296 1.883 2.0751v6.8787z" />
        <path d="m-61.562 73.744h29.167v6.9171h-29.167z" />
        <path d="m-61.562 85.081h29.167v83.62h-29.167z" />
        <path d="m-61.562 173.16h29.167v4.573h-29.167z" />
        <path d="m-61.562 182.19h29.167v11.49c0 0.95175-0.59622 1.9983-1.7677 1.9983h-25.363c-1.4988 0-2.0367-0.93589-2.0367-1.9983z" />
        <path d="m-30.751 87.387 33.218-8.9008c1.8226-0.48836 3.1531 0.02209 3.3906 0.9085l3.4919 13.032-38.222 10.242-3.1077-11.598c-0.53883-2.0109 0.15094-3.3943 1.2292-3.6832z" />
        <path d="m-27.979 106.95 38.28-10.257 1.2744 4.7561-38.073 10.202z" />
        <path d="m-25.533 116.08 38.191-10.233 13.31 49.672-38.444 10.301z" />
        <path d="m-11.458 170.1 38.202-10.236 2.4904 9.2944-38.007 10.184z" />
        <path d="m-7.9252 183.74 38.367-10.28 2.7222 10.159c0.28538 1.065-0.0566 2.5341-1.4602 2.9102l-33.341 8.9338c-1.8128 0.48575-3.048 0.0604-3.2809-0.78698z" />
      </g>
    </svg>
  );
}
