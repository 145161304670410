import { FC } from "react";
import { useTranslation } from "react-i18next";
import config from "../../variable";
import Link from "../Link/Link";
import clsx from "clsx";

type DiscoveryButtonProps = {
  className?: string;
};

export const DiscoveryButton: FC<DiscoveryButtonProps> = ({ className }) => {
  const { t } = useTranslation("discoveryLicence");

  return (
    <Link
      className={clsx(
        className,
        "whitespace-nowrap rounded border-2 border-purple-600 px-5 py-2 text-purple-600 !no-underline duration-500 hover:bg-purple-600 hover:text-white"
      )}
      link={config.kamaeEssentialUrl}
      crossSite
    >
      {t("discoveryLicence:discoverFullVersion")}
    </Link>
  );
};
