import Modal from "@kamae-apps/shared/Component/Modal/Modal";
import React, { useCallback, useEffect, useState } from "react";
import { Campaign } from "../../Types/Campaign";
import TUser from "@kamae-apps/shared/Types/TUser";
import { apiRequest, formatDate } from "@kamae-apps/shared/utils";

import FilledSecondaryButton from "@kamae-apps/shared/Component/Button/FilledSecondaryButton";
import { useLocation, useNavigate } from "react-router-dom";
import { Trans, useTranslation } from "react-i18next";

interface PhishingModalProps {
  user?: TUser;
  loaded: boolean;
}

export default function PhishingModal(props: PhishingModalProps) {
  const [display, setDisplay] = useState(false);

  const { t, i18n } = useTranslation("app");
  const navigate = useNavigate();
  const location = useLocation();
  const [newPhishing, setNewPhishing] = useState<Campaign[]>();

  const close = useCallback(
    (value: boolean) => {
      setDisplay(value);
      newPhishing?.forEach((v) => {
        apiRequest("/phishing/campaigns/" + v.id + "/seen", { method: "PUT" });
      });
      setNewPhishing([]);
    },
    [newPhishing]
  );

  useEffect(() => {
    if (props.user !== undefined && props.loaded)
      apiRequest<Campaign[]>("/phishing/campaigns?seen=false").then(
        (response) => {
          setNewPhishing(response ?? []);
        }
      );
  }, [props.user, props.loaded]);

  useEffect(() => {
    if (newPhishing !== undefined && newPhishing.length > 0) {
      if (location.pathname.startsWith("/phishing")) {
        newPhishing?.forEach((v) => {
          apiRequest("/phishing/campaigns/" + v.id + "/seen", {
            method: "PUT",
          });
        });
        setNewPhishing([]);
      } else {
        setDisplay(true);
      }
    }
  }, [newPhishing, location]);

  return (
    <Modal
      title={t("app:phishingModal.title")}
      display={display}
      setDisplay={close}
      width={"w-11/12 sm:w-2/3 sm:max-w-3xl"}
    >
      {newPhishing !== undefined && newPhishing.length !== 0 && (
        <div className={"flex flex-col items-center"}>
          <img
            alt={"phishing"}
            src={"/phishing_avatar.png"}
            className={"h-40"}
          />
          {newPhishing.map((v, i) => {
            return (
              <p key={i}>
                <Trans
                  t={t}
                  i18nKey="phishingModal.date"
                  ns="app"
                  values={{
                    date: formatDate(
                      newPhishing[i].end_date ?? new Date(),
                      i18n.language === "en"
                    ),
                  }}
                />
              </p>
            );
          })}
          <FilledSecondaryButton
            className={"mt-5"}
            onClick={() => {
              if (newPhishing?.length === 1) {
                navigate(`/phishing/${newPhishing[0].id}`);
              } else {
                navigate("/phishing");
              }
              close(false);
            }}
          >
            {t("app:phishingModal.result")}
          </FilledSecondaryButton>
        </div>
      )}
    </Modal>
  );
}
