export default function Phishing() {
  return (
    <svg
      width="28"
      height="28"
      viewBox="0 0 18 20"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M10.8605 6.7957C10.6316 6.5668 10.2609 6.5668 10.032 6.7957C9.80312 7.02461 9.80312 7.39531 10.032 7.62422C10.2207 7.81289 10.3613 8.03672 10.4473 8.28125H11.6605C11.5477 7.72227 11.2734 7.2082 10.8605 6.7957Z" />
      <path d="M9.37505 4.73477V4.55898C10.0641 4.24375 10.5469 3.38242 10.5469 2.34375C10.5469 1.02969 9.77466 0 8.78911 0C7.80356 0 7.0313 1.02969 7.0313 2.34375C7.0313 3.38242 7.51411 4.24375 8.20317 4.55898V4.73477C8.20317 5.39414 7.87388 6.03242 7.40239 6.28594C7.01997 6.4918 6.69575 6.77461 6.44458 7.10938H8.27856C8.95474 6.58828 9.37505 5.69609 9.37505 4.73477Z" />
      <path d="M8.44844 16.3754L0 10.3406V19.4141C0 19.7375 0.2625 20 0.585938 20H16.9922C17.3156 20 17.5781 19.7375 17.5781 19.4141V10.3406L9.12969 16.3754C8.92574 16.5207 8.65234 16.5207 8.44844 16.3754Z" />
      <path d="M16.9922 8.28125H11.6605C11.6992 8.47227 11.7188 8.66797 11.7188 8.86719C11.7188 10.4828 10.4047 11.7969 8.78906 11.7969C8.46562 11.7969 8.20312 11.5344 8.20312 11.2109C8.20312 10.8875 8.46562 10.625 8.78906 10.625C9.7582 10.625 10.5469 9.83633 10.5469 8.86719C10.5469 8.66445 10.5129 8.4668 10.4473 8.28125H0.585938C0.2625 8.28125 0 8.54375 0 8.86719V8.90078L8.78906 15.1785L17.5781 8.90039V8.86719C17.5781 8.54375 17.3156 8.28125 16.9922 8.28125Z" />
    </svg>
  );
}
