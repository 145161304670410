import UserProfile from "./Components/UserProfile";
import { LinkMenu } from "../LinkMenu/LinkMenu";
import { User } from "../User/User";
import config from "../../variable";
import { getBeltColor } from "@kamae-apps/shared/Types/Belt/Belt";
import { Scope, scopeHas } from "@kamae-apps/shared/Types/Scope";
import useSidebar from "@kamae-apps/shared/Hooks/sidebar/useSidebar";
import useUser from "../../hooks/useUser";
import { FC, useEffect, useState } from "react";
import { useWindowSize } from "@kamae-apps/shared/Hooks/useWindowSize";
import { isMobileScreen } from "@kamae-apps/shared/utils";
import { useLocation } from "react-router-dom";
import { TFunction } from "i18next";
import { Star } from "react-feather";
import { useFeatureFlags } from "../../hooks/posthog/useFeatureFlags";

interface SideBarProps {
  t: TFunction;
  loaded: boolean;
}

export const SideBar: FC<SideBarProps> = ({ t, loaded }) => {
  const size = useWindowSize();
  const location = useLocation();
  const { avatar, active } = useSidebar();
  const { user } = useUser();
  const featureFlag = useFeatureFlags();
  const [isAvatarDisplay, setIsAvatarDisplay] = useState(false);
  const [isTeamLeader, setIsTeamLeader] = useState(false);

  useEffect(() => {
    setIsAvatarDisplay(
      !isMobileScreen(size) ||
        (isMobileScreen(size) && location.pathname === "/home")
    );
    setIsTeamLeader(
      user.id === user.team.leader?.id && featureFlag.includes("team-leaders")
    );
  }, [size, location, user, featureFlag]);

  return (
    <div
      className={
        "flex max-h-screen w-full flex-col-reverse items-center justify-between md:h-full md:flex-col md:bg-white"
      }
    >
      {isAvatarDisplay && (
        <div
          className={
            "grad relative mt-20 w-1/2 rounded-3xl py-4 pt-24 md:static md:mt-0 md:w-full md:rounded-none md:rounded-b-3xl md:pt-0"
          }
        >
          <UserProfile
            name={user.last_name}
            first_name={user.first_name}
            img={
              avatar ??
              config.api +
                "/avatar/" +
                user.avatar +
                "/" +
                getBeltColor(user.level)
            }
            team={user.team.name}
            loaded={loaded}
            isProfileLinkActive={true}
          />
          {isTeamLeader && (
            <div className="flex w-full flex-col items-center justify-center text-white">
              <p>Capitaine</p>
              <Star className="text-purple-400" />
            </div>
          )}
        </div>
      )}
      <LinkMenu
        supervision={scopeHas(user.scope, Scope.COMPANY_ADMIN)}
        isTeamLeader={isTeamLeader}
        t={t}
        active={active}
      />
      <div className={"flex w-full items-center justify-between"}>
        <img
          src={"/logo_plateforme.png"}
          alt={"logo"}
          className={"xmd:w-36 mx-8 mb-3 hidden w-32 md:block"}
        />
        <div className={"block w-full md:hidden"}>
          <User
            t={t}
            loaded={loaded}
          />
        </div>
      </div>
    </div>
  );
};
