import { createContext, useContext, useState } from "react";

interface TSidebarContext {
  avatar: string | null;
  active: boolean;
}

const initContext: TSidebarContext = {
  avatar: null,
  active: true,
};

const useSidebarState = () => useState(initContext);

const SidebarContext = createContext<ReturnType<typeof useSidebarState> | null>(
  null
);

export function SidebarStateProvider(props: { children: any }) {
  const [sidebar, setSidebar] = useSidebarState();
  return (
    <SidebarContext.Provider value={[sidebar, setSidebar]}>
      {props.children}
    </SidebarContext.Provider>
  );
}

export const useSidebarSharedState = () => {
  const value = useContext(SidebarContext);
  if (value === null) {
    throw new Error("No provider for Sidebar State");
  }
  return value;
};
