import config from "../variable";
import useUser from "./useUser";
import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { CompanyStatus } from "@kamae-apps/shared/Types/Company/CompanyStatus";
import { getCompanyPlan } from "../utils";

const useIntercom = () => {
  const { user } = useUser();
  const { i18n } = useTranslation();

  const instantiateIntercom = useCallback(() => {
    (window as any).Intercom("boot", {
      api_base: "https://api-iam.intercom.io",
      app_id: config.intercomKey,
      custom_launcher_selector: "#intercom-launcher",
      language_override: i18n.language,
    });
  }, [i18n.language]);

  const updateIntercomWithUserInformation = useCallback(
    (hideLauncher?: boolean) => {
      (window as any).Intercom("update", {
        user_id: user.id,
        user_hash: user.intercom_hash,
        name: user.first_name + " " + user.last_name,
        email: user.email,
        language_override: i18n.language,
        company: {
          company_id: user.company.id,
          name: user.company.name,
          size: user.company.total_user,
          plan: getCompanyPlan(user.company.status),
        },
        hide_default_launcher: hideLauncher ?? false,
      });
    },
    [user, i18n.language]
  );

  const shutdownIntercom = useCallback(() => {
    (window as any).Intercom("shutdown");
  }, []);

  return {
    instantiateIntercom,
    updateIntercomWithUserInformation,
    shutdownIntercom,
  };
};

export default useIntercom;
