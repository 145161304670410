import React, { FC } from "react";
import Modal from "@kamae-apps/shared/Component/Modal/Modal";
import {
  BeltColor,
  getColorFromBelt,
} from "@kamae-apps/shared/Types/Belt/BeltColor";
import clsx from "clsx";
import { getNameFromBelt } from "../../Types/Belt/Belt";
import PrimaryButton from "@kamae-apps/shared/Component/Button/PrimaryButton";
import { useTranslation } from "react-i18next";

interface NewBeltModalProps {
  firstName: string;
  display: boolean;
  setDisplay: React.Dispatch<React.SetStateAction<boolean>>;
  beltColor: BeltColor;
}

export const NewBeltModal: FC<NewBeltModalProps> = ({
  firstName,
  display,
  setDisplay,
  beltColor,
}) => {
  const { t } = useTranslation([]);

  return (
    <Modal
      title=""
      display={display}
      setDisplay={setDisplay}
      width={"w-4/5 sm:w-1/2 sm:max-w-3xl"}
    >
      <div className={"mb-3 flex justify-center"}>
        <img
          className={"mr-5 hidden h-48 w-32 md:block"}
          src={"/kamae-san.png"}
          alt={"Kamae-San"}
        />
        <div>
          <h1 className="font-gilroy-bold text-2xl">
            {t("app:newBeltModal.title1", { name: firstName })}
          </h1>
          <h1 className="font-gilroy-bold text-2xl">
            {t("app:newBeltModal.title2")}{" "}
            <span className={clsx(getColorFromBelt(beltColor))}>
              {" "}
              {getNameFromBelt(beltColor, t)} !
            </span>
          </h1>
          <br />
          <p className="text-1xl font-gilroy-bold">
            {t("app:newBeltModal.title3")}
          </p>
          <br />
          <PrimaryButton
            type={"submit"}
            onClick={() => {
              setDisplay(false);
            }}
          >
            {t("app:newBeltModal.buttonLabel")}
          </PrimaryButton>
        </div>
      </div>
    </Modal>
  );
};
