import { posthog } from "posthog-js";
import { useEffect, useState } from "react";

export function useFeatureFlags(): string[] {
  const [featureFlags, setFeatureFlags] = useState<string[]>([]);

  useEffect(() => {
    posthog.onFeatureFlags((flags) => {
      if (flags) {
        setFeatureFlags(flags);
      }
    });
  }, []);

  return featureFlags;
}
