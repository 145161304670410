import Button from "./Button";
import { MouseEventHandler, ReactNode } from "react";
import clsx from "clsx";

interface FilledSecondaryButtonProps {
  onClick?: MouseEventHandler<HTMLButtonElement>;
  disabled?: boolean;
  type?: "button" | "submit" | "reset";
  spinning?: boolean;
  children?: ReactNode;
  title?: string;
  className?: string;
}

export default function FilledSecondaryButton(
  props: FilledSecondaryButtonProps
) {
  const css = clsx(
    "whitespace-nowrap border-purple-600 bg-purple-600 text-white hover:scale-105 focus:outline-none disabled:border-purple-200 disabled:bg-purple-200 disabled:hover:scale-100 disabled:hover:bg-purple-200",
    props.className
  );
  return (
    <Button
      className={css}
      onClick={props.onClick}
      disabled={props.disabled}
      type={props.type}
      spinning={props.spinning}
      title={props.title}
    >
      {props.children}
    </Button>
  );
}
