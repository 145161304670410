import React, { Suspense } from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./Components/App/App";
import { BrowserRouter } from "react-router-dom";
import config, { auth0Config, cryptrConf } from "./variable";
import { ElearningContext } from "./Context";
import { CryptrProvider } from "@cryptr/cryptr-react";
import posthog from "posthog-js";
import { Auth0Provider } from "@auth0/auth0-react";
import { DevSupport } from "@react-buddy/ide-toolbox";
import { ComponentPreviews, useInitial } from "./dev";
import { SidebarStateProvider } from "@kamae-apps/shared/Hooks/sidebar/Context";
import { UserStateProvider } from "./hooks/Context";
import { ApiStatusStateProvider } from "@kamae-apps/shared/Hooks/useApiStatus/Context";
import i18n from "./i18n/i18n";
import { I18nextProvider } from "react-i18next";
import Loading from "@kamae-apps/shared/Component/Loading/Loading";
import { initializeFaro } from "@grafana/faro-web-sdk";

if (config.env !== "development") {
  initializeFaro({
    url: config.grafanaFaroUrl,
    app: {},
  });
}

posthog.init(config.posthogKey, {
  api_host: "https://d2bqsfxsrv9m8.cloudfront.net",
  capture_pageview: true,
  opt_in_site_apps: true,
  autocapture: false,
  disable_persistence: true, // Do not use cookies to remain GDPR compliant
  disable_session_recording: true,
  advanced_disable_feature_flags_on_first_load: true,
  // Blacklist most properties for now to remain GDPR compliant
  property_blacklist: [
    "$os",
    "$browser",
    "$device_type",
    "$current_url",
    "$host",
    "$browser_version",
    "$browser_language",
    "$lib",
    "$lib_version",
    "$referrer",
    "$referring_domain",
    "$device_id",
    "$session_id",
    "$recording_id",
    "$window_id",
    "$anon_distinct_id",
  ],
});

ReactDOM.render(
  <React.StrictMode>
    <I18nextProvider i18n={i18n}>
      <ElearningContext.Provider value={new Map()}>
        <ApiStatusStateProvider>
          <CryptrProvider {...cryptrConf}>
            <Auth0Provider
              domain={auth0Config.loginDomain}
              clientId={auth0Config.clientId}
              redirectUri={config.app}
              audience={config.api}
              scope={"read:all"}
            >
              <BrowserRouter>
                <UserStateProvider>
                  <SidebarStateProvider>
                    <DevSupport
                      ComponentPreviews={ComponentPreviews}
                      useInitialHook={useInitial}
                    >
                      <Suspense fallback={<Loading />}>
                        <App />
                      </Suspense>
                    </DevSupport>
                  </SidebarStateProvider>
                </UserStateProvider>
              </BrowserRouter>
            </Auth0Provider>
          </CryptrProvider>
        </ApiStatusStateProvider>
      </ElearningContext.Provider>
    </I18nextProvider>
  </React.StrictMode>,
  document.getElementById("kamaeRoot")
);

if ("serviceWorker" in navigator) {
  navigator.serviceWorker
    .register("/sw.js")
    .then((serviceWorker) => {
      // eslint-disable-next-line no-console
      console.log("Service Worker registered:", serviceWorker);
    })
    .catch((error) => {
      console.error("Error while registering service worker: ", error);
    });
}
