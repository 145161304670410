import React, { FC } from "react";
import { User } from "../User/User";
import { useLocation } from "react-router-dom";
import { getTitle } from "../../utils";
import ScrollText from "@kamae-apps/shared/Component/ScrollText/ScrollText";
import { TFunction } from "i18next";

interface HeaderProps {
  t: TFunction;
  loaded: boolean;
}

export const Header: FC<HeaderProps> = ({ loaded, t }) => {
  const uri = useLocation();

  return (
    <header className={"flex h-20 items-center justify-center"}>
      <ScrollText
        className={
          "font-gilroy-bold my-4 w-full text-center text-4xl font-bold md:w-auto md:text-left"
        }
      >
        {getTitle(uri.pathname, t)}
      </ScrollText>
      <div className={"hidden grow md:block"}>
        <User
          t={t}
          loaded={loaded}
        />
      </div>
    </header>
  );
};
