import { useUserConfigSharedState } from "./Context";
import { apiRequest } from "../../utils";
import { useCallback } from "react";

export default function useUserConfig() {
  const [userConfig, setUserConfig] = useUserConfigSharedState();
  return {
    configs: userConfig,
    setConfig: useCallback(
      (key: string, value: string) => {
        apiRequest(`/user/config/${key}`, {
          method: "PUT",
          body: { value: value },
        }).then(() => {
          userConfig?.set(key, value);
          setUserConfig(userConfig);
        });
      },
      [setUserConfig, userConfig]
    ),
  };
}
